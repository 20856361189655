<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'SimpleInvoiceApp',
}
</script>

<style lang="scss">
@font-face {
  font-family: "ProximaNova-Bold";
  src: url('./fonts/ProximaNova-Bold/ProximaNova-Bold.eot');
  src: url('./fonts/ProximaNova-Bold/ProximaNova-Bold.woff') format('woff'),
  url('./fonts/ProximaNova-Bold/ProximaNova-Bold.ttf') format('truetype')
}

@font-face {
  font-family: "ProximaNova-Semibold";
  src: url('./fonts/ProximaNova-Semibold/ProximaNova-Semibold.eot');
  src: url('./fonts/ProximaNova-Semibold/ProximaNova-Semibold.woff') format('woff'),
  url('./fonts/ProximaNova-Semibold/ProximaNova-Semibold.ttf') format('truetype')
}

@font-face {
  font-family: 'ProximaNova-Regular';
  src: url('./fonts/ProximaNova-Regular/ProximaNova-Regular.eot');
  src: url('./fonts/ProximaNova-Regular/ProximaNova-Regular.woff') format('woff'),
  url('./fonts/ProximaNova-Regular/ProximaNova-Regular.ttf') format('truetype')
}
html {
  font-size: 62.5%;
  font-family: $proxima;
}
body {
  font-size: 1.6rem;
  line-height: 1;
  background-color: $bg-main;
}
* {
  box-sizing: border-box;
}
</style>
