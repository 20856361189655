import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    uuid: null,
    businessData: {
      business_name: '',
      full_address: '',
      tax_id: '',
      phone: '',
      email: '',
      website: '',
    },
    billingData: {
      invoice_no: '',
      invoice_currency: 'USD',
      invoice_date: new Date(),
      due_date: new Date(),
      bill_to: '',
      bill_to_cc: '',
      items: null,
      notes_recipient: '',
      terms: '',
      attachments: []
    },
    generatedData: null,
  },
  plugins: [createPersistedState()],
  mutations: {
    SET_UUID(state, uuid) {
      state.uuid = uuid;
    },
    SET_BUSINESS_NAME(state, name) {
      state.businessData.business_name = name;
    },
    SET_FULL_ADDRESS(state, address) {
      state.businessData.full_address = address;
    },
    SET_TAX_ID(state, taxId) {
      state.businessData.tax_id = taxId;
    },
    SET_PHONE(state, phone) {
      state.businessData.phone = phone;
    },
    SET_EMAIL(state, email) {
      state.businessData.email = email;
    },
    SET_WEBSITE(state, website) {
      state.businessData.website = website;
    },
    SET_INVOICE_NO(state, payload) {
      state.billingData.invoice_no = payload;
    },
    SET_INVOICE_DATE(state, payload) {
      state.billingData.invoice_date = payload;
    },
    SET_DUE_DATE(state, payload) {
      state.billingData.due_date = payload;
    },
    SET_INVOICE_CURRENCY(state, payload) {
      state.billingData.invoice_currency = payload;
    },
    SET_BILL_TO(state, payload) {
      state.billingData.bill_to = payload;
    },
    SET_CC(state, payload) {
      state.billingData.bill_to_cc = payload;
    },
    SET_INVOICE_DATA(state, payload) {
      state.billingData.items = payload;
    },
    SET_NOTES(state, payload) {
      state.billingData.notes_recipient = payload;
    },
    SET_TERMS(state, payload) {
      state.billingData.terms = payload;
    },
    SET_ATTACHMENTS(state, payload) {
      state.billingData.attachments.push(payload);
    },
    CLEAR_ATTACHMENTS(state, payload) {
      state.billingData.attachments = payload;
    },
    SET_GENERATED_DATA(state, payload) {
      state.generatedData = payload;
    }
  },
  actions: {
    clearBusinessData({ commit }) {
      /* Clean up business data */
      commit('SET_INVOICE_DATE', new Date());
      commit('SET_DUE_DATE', new Date());
      commit('SET_INVOICE_NO', '');
      /* Clean up billing data */
      commit('SET_BILL_TO', '');
      commit('SET_CC', '');
      commit('SET_INVOICE_DATA', null);
      /* Clean up note and terms data */
      commit('SET_NOTES', '');
      commit('SET_TERMS', '');
      commit('CLEAR_ATTACHMENTS', []);
    },
  },
  modules: {
  }
})
