import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import './vee-validate'

import 'normalize.css'

import CirclePlus from "@/assets/svg/circle-plus.svg";
import Calendar from "@/assets/svg/calendar.svg";
import Clip from "@/assets/svg/clip.svg";
import ArrowBack from "@/assets/svg/arrow-back.svg";
import Cross from "@/assets/svg/cross.svg";
import ResetData from "@/assets/svg/reset-data.svg";
import CircleOk from "@/assets/svg/circle-ok.svg";
import Success from "@/assets/svg/success.svg"
import Download from "@/assets/svg/download.svg"

Vue.component('circle-plus-icon', CirclePlus);
Vue.component('calendar-icon', Calendar);
Vue.component('clip-icon', Clip);
Vue.component('arrow-back-icon', ArrowBack);
Vue.component('cross-icon', Cross);
Vue.component('reset-icon', ResetData);
Vue.component('circle-ok-icon', CircleOk);
Vue.component('success-icon', Success);
Vue.component('download-icon', Download);

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

Vue.filter("localString", function(value) {
  return value.toLocaleString();
});
Vue.filter("roundValue", function(value, precision = 2) {
  return value.toFixed(precision).toLocaleString();
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
